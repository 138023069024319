import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import PoolHealthBanner from '../../images/products/MineralSwimV2_BENEFITS_CustomerCare_1920x1000.jpg'
import Holder from '../../images/image.png'
import { Link } from 'gatsby'

const PoolHealth = () => (
	<Layout style={{ paddingBottom: '1rem' }}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Enhance Your Pool's Health with Mineral Swim Solutions</title>
			<meta
				name="description"
				content="When purchasing a Mineral Swim system, as part of the customer care plan, Maytronics authorised dealer carry out a complimentary health check and service of your pool six weeks and six months, after the new installation or conversion. If you have access to Maytronics for Life program then you will be offered a yearly pool health check.  "
			/>
			<meta
				name="keywords"
				content="pool health"
			/>
			<link rel="canonical" href="https://mineralswim.com/benefits/pool-health/" />
		</Helmet>
		<main  className="bg-white relative">
			<header className='w-screen absolute main-page-header bg-center z-10'
						>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>						
							<div className='hidden lg:flex col-span-6'></div>
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
								<div className='bg-white bg-opacity-60 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Customer Care</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>	
									</div>
									<p className='text-black md:text-base leading-6'>For complete peace of mind, your Mineral Swim™ system comes with dedicated lifetime support and complimentary pool system health checks after installation.
										</p>					
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to="/s/?page=benefits&article=pool-health">             
									<p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>			
									</div>	
									</div>								
									</div>                
							</div>
								</header>
				<div className='top-0 bg-cover bg-right lg:bg-left z-0' style={{
                backgroundImage: `url(${PoolHealthBanner})`,
								backgroundAttachment: 'fixed',
								position: 'relative',
								height: '90vh',
            }}
						data-scroll
      data-scroll-speed="-10"
						>
							
								</div>
								<div className='relative -mt-24 md:-mt-24 lg:-mt-40 pt-40 pb-20 bg-white z-10'>
				<section  id="main-content" className="max-w-screen-xl font-display text-base mx-auto mb-20 text-justify md:text-left px-8 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-10">
				<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Enjoy Crystal-Clear Water with Expert Customer Care</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>There's nothing quite like a refreshing dip in a crystal-clear pool on a hot summer day. Maintaining your pool's health is essential, not just for its aesthetic appeal but for preventing waterborne illnesses and extending its lifespan. Keeping your pool well-maintained ensures the overall quality and enjoyment for its users. By following guidelines and adopting good practices, your pool will remain healthy, balanced, and sparkling all year round.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/66249354223enjoycrystal-clearwater_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=mufhgL4yPV2xvWdvqNeb5Q3PfHdojBGHCizIR5QFKqWo7TOpIKmDHXt5QbmZOKSYA9L26Kkrgo2l47pJYb9y8tLVZzcZ%2BX%2FTg4VHOh%2BSZrwrtrwxRzIeQvi46GSrbtcnbHv2YzRYPgNo9tfaHYlQAxvB8HrZY8Ml99L7jfaiITn3qn10c%2BEDQ3EMPARUonw4ZGnW0Hc%2BxcJ9GyKwZCO61wXD%2FCn8miZy%2Fq%2B0JtNXTEGTxPsPV0I59AYUU31geRb8kwqSrXecPzfgk4oOczb4l6tRXsAnwMjg31aCGr4SM8DqYWr4iGR5wW1%2B1xwnr14onq6iyeml9VALa1cpU084kA%3D%3D" alt='Enjoy Crystal Clear' className='w-full h-72 border object-cover rounded-2xl'/>		
				<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Exclusive Benefits For Maytronics Mineral Swim™ Customers</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Our Maytronics Mineral Swim™ customers enjoy exclusive access to a Customer Care Plan and the Maytronics Benefits For Life program, guaranteeing the longevity of your Mineral Swim™ system and its numerous advantages.</p>
					</div>		
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/8937128058exclusivebenefitsformaytronics-_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=EKa%2BHKtj4RETTX4dE81DdPWcZhpt%2BGe17p68KALDNK0CuLj1ucqC%2BYMJye6LwdZPzVFpTIcLC6VXJT5BIgDtpGbzzJKSoCddFefzt5vQlpRTtzjZ1RvGZjkS1uBbXPW%2F1%2FxfOBVgDCs5ggRDvHk5GD0J90Zi64Wp9RFiZFPhVp0R8uOeLc8vXeGJfkLR%2FMbyxPeHpOL9CWQOI%2Fnt17sdu2%2FtNX%2BYaBSEyz26CnODobxOsyzk%2FUV5rECsDaqgRkhUqNUw6%2BLtoVXjhOZJf87LN3kscCNGRJVSQutVuObZes52tdgtml%2FrtCa%2Fl6LJCRhAgAmZUZycDkJWGAyv%2F5Mqxw%3D%3D" alt='Exclusive Benefits for Maytronics' className='w-full h-72 border object-cover rounded-2xl'/>			
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Professional Pool Health Checks </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Ensure the ongoing health of your pool with complimentary health checks and services offered by Maytronics' authorised Elite dealers. After installing the Mineral Swim™ system, register your details online to access these complimentary pool health checks. Authorised dealers provide a complimentary health check and service of your pool between six weeks and six months after installation or conversion. This essential service helps identify any issues with your pool and ensures your new Mineral Swim™ system functions optimally.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/68880446711professionalpoolhealthchecks_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=P6wlC6%2BDG4gzZkjf%2FsOom5qjZ2ggPtWL7YSnwDqSYWULiuXBHqQFcJtxAxlz%2FswY9%2Bejv2%2FSvyCVQbnNvyVQCUn2qXfiP4xY9b4vYEzpLhsIHHoMlXz6RdWMAJhqjVIiCVT4%2FFH9EG2LGQ33Eysgn%2F%2FekyT6bFpt1%2B6Gjs8M2crbbLkJvUTemaWTXXz2VKyUyWz5F8XHEaRDwJsw1IBM417SPcIpjQVqh5wWpPUA29g7ZLfAVYIbnYoWHILueaEDTRrpJctMo%2Ft7DeafqqV%2FhWx3%2FmZjf%2B3A6gSxDrEOi5iNLWjPQq2ANgwcyBRAhCGDqPWXGNPrYVJ%2F9XGrOmxj9Q%3D%3D" alt='Professional Pool Health Checks' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Maytronics for Life </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>With your Mineral Swim™ System, you gain exclusive access to the Maytronics for Life program, offering a range of lifetime benefits, including a free yearly pool health check. During this service, a trained technician will inspect the pool equipment, test the water chemistry, and check for any potential issues or needed repairs. This proactive approach helps identify and address problems before they become serious, ensuring your Mineral Swim™ pool is always in top condition. 
                    </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/92659292855maytronicsforlife_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=o1sg4NS1xyWPEqvWL8fygJIIQh%2B8uSxTUNfzNWNvtp%2BfSzrKv4AKxj9244aHBJ2NTaaVGHcYgXjmzBRNJHkDKuNmPfjkYxh8z7Bo19Pw476tl3XaV5qtuGjGRHn5xeoPzbf96S4MtD30ET1HvxRXPlS78HgqeDYu%2BC1gruemwMPkA66eni6ywYC0VFDFGbm%2FyLwP6S2xBeb06hlXSLPjnhLoQwiFqHsuyin6mo6NISdZomU2YqCgKHCqvKmO1kZMqPI3u6EU1v7gU0w11VaDIxCg0f%2FXb3oWzdggOgKwo8ll7dAhTQdvw46ptS8t3UYUlElX5YpA09jud%2Ftt2SVijg%3D%3D" alt='Maytronics For Life' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Maintaining Your Mineral Pool</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Regular pool maintenance and health checks are crucial for mineral pools, as these systems rely on a delicate balance of minerals to keep the water clean and healthy. Improper mineral levels can lead to cloudy water, scaling, or bacterial growth. Regular health checks and maintenance ensure your mineral pool functions correctly, and the water remains clean, clear, and healthy.
					</p>
					<br/>
					<p>In addition to regular health checks, you should:</p>
					<p><span className='font-semibold'>Test Water Chemistry Regularly:</span> Adjust mineral levels to prevent scaling, cloudiness, or bacterial growth.</p>
					<p><span className='font-semibold'>Clean the Pool Regularly:</span> Ensure the filter functions properly to maintain your pool's health.</p>
					<p>By staying on top of these essential tasks, you'll keep your swimming pool in peak condition year-round!</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/52749839744maintainingyourmineralpool_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=PxAZlqPEko1ionVgY%2BpuAPb%2Fmz%2Fh8EuSMrkGWxd06HWDhZvJL58%2Bp4BGCvJ%2F1nTBJI3XPrQ0kAmETt0ejHy%2FqwEN2KPVULEvLrOYLTvYc8yQiPDUm%2FaklhBtfqnavrmB3bX8LM1dVnQYA6UeXeDe6r6PZcbcTuJtcvCGEHmQ4VlKVXPabZs0HBmjZ8vM1C3bVejB%2FUh4AY7l6ejzYbXIdyzCGzjRD%2Bg71gZG1NDijbovcbus2oFb2PNvB6OmJa5xiBEO4dCOcGLFj50XGJ5LcGNN7OVciGANonZPDSe3v3W%2BO2Y5Ya0AnpZVb0YC2p5jCaIcqqLyPY7EjAGy6bMxJg%3D%3D" alt='Maintain Your Mineral Pool' className='w-full h-72 border object-cover rounded-2xl'/>	
				</section>
				<div className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:flex-1 font-display text-base font-semibold uppercase">
					<Link
					to="/benefits"
									className="cursor-pointer flex items-center justify-center  w-48 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300 whitespace-nowrap"
								>
									Back to Benefits
								</Link>
						<Link
							to="/register"
							className="whitespace-nowrap inline-flex items-center justify-center w-48 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white"
						>
							Register
						</Link>
					</div>
				</div>
				</main>
	</Layout>
)

export default PoolHealth